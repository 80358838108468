import Link from "next/link";

const Header_NavbarItem = ({text, href, onClick}) => (
  <li
    className="w-full py-2 md:px-3 hover:opacity-50 transition-all duration-500 ease-in-out last:pr-0"
    onClick={onClick}
  >
    <Link href={href} passHref scroll={false}>
      <a className="inline-block w-full no-underline hover:text-gray-800">
        {text}
      </a>
    </Link>
  </li>
);

export default Header_NavbarItem;
