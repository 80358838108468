import css from "./Spinner.module.css"

const Spinner = () => {
  return (
    <div className={css.skCubeGrid}>
      <div className={`${css.skCube} ${css.skCube1}`}></div>
      <div className={`${css.skCube} ${css.skCube2}`}></div>
      <div className={`${css.skCube} ${css.skCube3}`}></div>
      <div className={`${css.skCube} ${css.skCube4}`}></div>
      <div className={`${css.skCube} ${css.skCube5}`}></div>
      <div className={`${css.skCube} ${css.skCube6}`}></div>
      <div className={`${css.skCube} ${css.skCube7}`}></div>
      <div className={`${css.skCube} ${css.skCube8}`}></div>
      <div className={`${css.skCube} ${css.skCube9}`}></div>
    </div>
  )
}

export default Spinner
