import simpleSvgPlaceholder from "@cloudfour/simple-svg-placeholder"
import Cookies from "js-cookie"

export const COLORS = {
  TEAL_800: "#517678"
}

// Images

export const svgPlaceholder = simpleSvgPlaceholder({
  width: 622.5,
  height: 622.5,
  bgColor: "#edf2f7",
  text: "",
})

export const getCloudinaryUrl = (strapiImageObject) => {
  const url = strapiImageObject.attributes.url
  return url.replace("/upload", "/upload/ar_1:1,w_1280,h_1280,c_fill/dpr_auto,q_auto,f_auto")
}

// Navbar and Footer

export const getFooterItems = ({
  sectionPrivacy,
  sectionImpressum,
}) => [
    {slug: sectionPrivacy.slug, label: sectionPrivacy.label},
    { slug: sectionImpressum.slug, label: sectionImpressum.label },
  ]

export const getNavbarItems = ({
  sectionHero,
  sectionAbout,
  sectionServices,
  sectionContact,
}) => ([
  {uid: sectionHero.uid, label: sectionHero.menuLabel},
  {uid: sectionAbout.uid, label: sectionAbout.menuLabel},
  {uid: sectionServices.uid, label: sectionServices.menuLabel},
  {uid: sectionContact.uid, label: sectionContact.menuLabel},
])

// Cookies

const setCookie = ({ name, value, secure }) =>
  Cookies.set(name, value, { expires: 365, sameSite: "strict", secure: secure })
const getCookie = (name) => Cookies.get(name)

export const getHideConsentMessageCookie = () => getCookie("hideCookiesConsent")
export const setHideConsentMessageCookie = (is_dev) =>
  setCookie({ name: "hideCookiesConsent", value: "yes", secure: !is_dev })
