import Footer_Item from "./Footer_Item";

const Footer = ({footerItems}) => (
  <footer
    id="footer"
    className="container fixed bottom-0 z-10 w-full px-4 py-2 text-sm text-teal-900 bg-white sm:text-base md:px-10 font-title"
  >
    <ul className="flex flex-row justify-center">
      {footerItems &&
        footerItems.map((item, index) => {
          if (!item.label || !item.slug) return;
          return <Footer_Item key={index} label={item.label} href={item.slug} />
        })}
    </ul>
  </footer>
);

export default Footer;
