import React from "react";

export const Logo = ({onClick, color}) => {
  return (
    // TODO: mikhail. remove what is not needed
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0"
      y="0"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <style type="text/css">{`.st0{fill:#BCBDBD;} .st1{fill:${color};}`}</style>
      <path
        className="st0"
        d="M500.23,120.98v-4.42h-52.14v-29.1h-4.42v29.1H227.7l-8.81-18.64l-4,1.88l7.92,16.76h-32.69l28.09-59.75  l-4-1.89l-28.98,61.64h-45.06v4.42h42.97L74.97,350.5H38.15v4.42h34.73l-5.17,10.98H34.93v4.42h30.69l-16.46,34.93H-0.52v4.42h47.59  l-6.64,14.09l4,1.88l7.53-15.97h38.88l-15.73,33.14l3.99,1.91l16.63-35.05h265.55l16.1,34.08l0.73,1.54l4-1.89l-15.94-33.73h83.07  v-4.42h-85.16l-134.3-284.27h213.88v34.87h-62.06v4.42h62.06v24.49h4.42v-24.49h27.16v-4.42h-27.16v-34.87H500.23z M116.83,354.92  l-5.21,10.98H72.6l5.17-10.98H116.83z M109.52,370.33l-16.58,34.93h-38.9l16.46-34.93H109.52z M114.42,370.33h29.47v-4.42h-27.37  l5.21-10.98h25.38v-4.42h-23.28l7.12-15.01l-3.99-1.91l-8.03,16.92H79.86l108.18-229.52h36.87l134.3,284.27H97.84L114.42,370.33z"
      />
      <polygon
        className="st1"
        points="184.39,116.42 72.36,354.95 121.82,354.97 184.85,221.19 252.3,365.82 67.24,365.82 46.49,409.95   322.99,410.06 184.39,116.42 "
      />
      <polygon
        className="st1"
        points="222.49,116.3 243.2,160.6 317.55,160.6 317.56,317.56 361.89,409.9 361.89,160.61 448.24,160.58   448.24,116.31 222.49,116.3 "
      />
    </svg>
  );
};

export const LogoLink = React.forwardRef(({onClick, color}, _ref) => (
  <Logo onClick={onClick} color={color} />
));
