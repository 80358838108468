const CookiesConsentMessage = ({handleCookiesConsentClose}) => (
  <div className="fixed bottom-0 z-10 flex flex-row justify-center w-full px-4 py-3 text-white bg-teal-900 leading-5 md:text-xl md:leading-6 lg:leading-7 lg:text-2xl sm:text-base md:px-10 font-title">
    <div className="my-auto text-right">
      Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden,
      dass wir Cookies verwenden.
    </div>
    <div className="flex pl-5 sm:pl-10 lg:pl-20">
      <button
        onClick={handleCookiesConsentClose}
        className="w-full h-full px-4 py-2 m-auto text-teal-900 bg-white border border-gray-400 rounded shadow focus:outline-none hover:bg-gray-100 my-tap-highlight"
        type="submit"
        aria-label="Senden nachricht"
      >
        OK
      </button>
    </div>
  </div>
)

export default CookiesConsentMessage
