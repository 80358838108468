import React, {useEffect, useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import CookiesConsentMessage from "./CookiesConsentMessage"
import {
  getHideConsentMessageCookie,
  setHideConsentMessageCookie,
} from "./../lib/utils"

function Layout({children, footerItems, navbarItems}) {
  const [mounted, setMounted] = useState(false);

  // don't pre-render cookie message to avoid: 
  // Hydration failed because the initial UI does not match what was rendered on the server
  useEffect(() => setMounted(true), []);

  const [
    showCookiesConsentMessage,
    setShowCookiesConsentMessage,
  ] = React.useState(!getHideConsentMessageCookie())

  const handleCookiesConsentClose = () => {
    setShowCookiesConsentMessage(false)
    setHideConsentMessageCookie(process.env.NEXT_PUBLIC_IS_DEV)
  }

  return (
    <div className="flex flex-col items-center text-base text-center text-gray-800 bg-white sm:text-lg md:text-xl font-base">
      <Header navbarItems={navbarItems} />
      <main className="flex-1 w-full">{children}</main>
      <Footer footerItems={footerItems} />
      {showCookiesConsentMessage && mounted && (
        <CookiesConsentMessage
          handleCookiesConsentClose={handleCookiesConsentClose}
        />
      )}
    </div>
  );
}

export default Layout;
