import React from "react";
import {motion} from "framer-motion";

import {fetchAPI} from "../lib/api";
import {getFooterItems, getNavbarItems} from "../lib/utils";

import Layout from "../components/Layout";
import SectionHero from "../components/SectionHero";
import SectionAbout from "../components/SectionAbout";
import SectionContact from "../components/SectionContact"
import SectionServices from "../components/SectionServices";
import Section_Divider from "../components/Section_Divider"

const Home = ({
  aboutData,
  footerItems,
  heroData,
  navbarItems,
  servicesData,
  contactData,
}) => {
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1, transition: {duration: 1}}}
      exit={{opacity: 0}}
      className="container mx-auto"
    >
      <Layout footerItems={footerItems} navbarItems={navbarItems}>
        <div className="flex flex-col items-center justify-center">
          <SectionHero data={heroData} />
          <SectionAbout data={aboutData} />
          <Section_Divider />
          <SectionServices data={servicesData} />
          <Section_Divider />
          <SectionContact data={contactData} />
        </div>
      </Layout>
    </motion.div>
  );
};

export async function getStaticProps() {
  // Run API calls in parallel
  const [aboutRes, heroRes, privacyRes, impressumRes, servicesRes, contactRes] = await Promise.all([
    fetchAPI("/section-about", {populate: "*"}),
    fetchAPI("/section-hero", {populate: "*"}),
    fetchAPI("/section-privacy", {populate: "*"}),
    fetchAPI("/section-impressum", {populate: "*"}),
    fetchAPI("/section-services", {
      populate: ["works", "works.coverImage.media"],
    }),
    fetchAPI("/section-contact", {populate: "*"}),
  ]);

  const navbarItems = getNavbarItems({
    sectionHero: heroRes.data.attributes,
    sectionAbout: aboutRes.data.attributes,
    sectionServices: servicesRes.data.attributes,
    sectionContact: contactRes.data.attributes,
  });

  const footerItems = getFooterItems({
    sectionPrivacy: privacyRes.data.attributes,
    sectionImpressum: impressumRes.data.attributes,
  });

  return {
    props: {
      aboutData: aboutRes.data.attributes,
      footerItems: footerItems,
      heroData: heroRes.data.attributes,
      navbarItems: navbarItems,
      servicesData: servicesRes.data.attributes,
      contactData: contactRes.data.attributes,
    },
    revalidate: 1,
  };
}

export default Home;
