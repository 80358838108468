import Image from "next/image";

import Section_Title from "./Section_Title";
import Section_Content from "./Section_Content";
import Section_Description from "./Section_Description";

const SectionAbout = ({data}) => (
  <section id={data.uid} className="flex flex-col w-full">
    <Section_Title title={data.title} />
    <Section_Content>
      <Image
        className="mx-auto mb-4 grayscale brightness-125"
        src="/axel-thiele.jpeg"
        alt="Axel Thiele"
        width={320}
        height={213}
      />
      <Section_Description description={data.description} />
    </Section_Content>
  </section>
);

export default SectionAbout;
