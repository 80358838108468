import React from "react";
import Image from "next/image";
import VisibilitySensor from "react-visibility-sensor";
import Link from "next/link";
import cn from "classnames";

const SectionServices_Work = ({work, isEven}) => {
  const coverImageUrl = work.attributes.coverImage.data.attributes.url;
  const coverImageAlt = work.attributes.coverImage.data.attributes.alternativeText;
  const [isVisible, setIsVisible] = React.useState(false);
  const onChange = (isVisible) => setIsVisible(isVisible);

  return (
    <VisibilitySensor
      onChange={onChange}
    >
      <Link href="leistungen/[slug]" as={`/leistungen/${work.attributes.slug}`} passHref>
        <div className={cn(
          {
            "shadow-my-1 xl:shadow-none": isVisible,
            "shadow-none": !isVisible,
            // mr-1 is needed so it doesn't show 3 tiles in a row because of w-4/12
            "mr-10 lg:mr-1": isEven,
            "ml-10 lg:ml-1": !isEven,
          },
          "relative my-16 sm:w-5/12 lg:w-4/12 cursor-pointer group transition duration-500 ease-in-out my-tap-highlight hover:shadow-my-1")}>
          <Image
            className={cn(
              {
                "xl:grayscale filter-none": isVisible,
                grayscale: !isVisible,
              },
              "transition-all group-hover:filter-none duration-1000 ease-in-out"
            )}
            alt={coverImageAlt}
            // combined with responsive layout width and heigh only represent the image aspect ratio
            layout="responsive"
            width="500"
            height="400"
            src={coverImageUrl}
            placeholder="blur"
            blurDataURL={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8d+5qPQAH1QLyTdgOVgAAAABJRU5ErkJggg=="}
          />
          <div
            className={cn(
              {
                "top-0 left-0 mt-12 border-l-0": isEven,
                "bottom-0 right-0 mb-12 border-r-0": !isEven,
              },
              "border-white opacity-80 border-2 leading-tight pt-2 pb-3 absolute px-4 text-xl sm:text-base md:text-lg text-gray-100 lg:text-2xl font-title my-w-80 bg-my-green-999"
            )}
          >
            {work.attributes.title}
          </div>
        </div>
      </Link>
    </VisibilitySensor>
  );
};

export default SectionServices_Work;
