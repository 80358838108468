import Header_NavbarItem from "./Header_NavbarItem";

const Header_Navbar = ({navbarItems, isMenuOpened, onClick}) => {
  return (
    <nav className={`${isMenuOpened ? "block" : "hidden"} md:block`}>
      <ul className="flex flex-col items-center justify-end text-xl tracking-wide whitespace-nowrap md:flex-row font-title">
        {navbarItems.map((item, index) => {
          if (!item.label || !item.uid) return;
          return (
            <Header_NavbarItem
              key={index}
              text={item.label}
              href={`/#${item.uid}`}
              onClick={onClick}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Header_Navbar;
