import {renderToStaticMarkup} from "react-dom/server";
import HeroBackground from "./HeroBackground";

const SectionHero = ({data}) => {
  const svgString = encodeURIComponent(
    renderToStaticMarkup(<HeroBackground opacity="0.5" />)
  );

  return (
    <header
      id={data.uid}
      className="flex flex-col justify-center w-full mb-24 overflow-hidden bg-cover bg-my-left-45 xl:bg-left-top h-my-100vh my-hero"
      style={{
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
      }}
    >
      <div className="flex flex-col justify-center w-full py-6 mb-24 bg-white shadow-md sm:mb-6 md:mb-16 xl:mb-16">
        <h1
          // pl-4 needed to compensate letter spacing addition to thre right of the last letter
          className="pt-2 pl-4 mb-4 text-2xl text-teal-900 sm:text-3xl md:text-4xl font-title tracking-my-header"
        >
          {data.header}
        </h1>
        <div
          // pl-3 needed to compensate letter spacing addition to thre right of the last letter
          className="pl-3 text-sm leading-tight text-gray-800 opacity-75 sm:leading-tight md:leading-tight lg:mb-0 sm:text-base md:text-xl tracking-my-subheader"
          dangerouslySetInnerHTML={{__html: data.subheader}}
        />
      </div>
    </header>
  );
};

export default SectionHero;
