import Link from "next/link";

const Footer_Item = ({label, href}) => (
  <li className="py-2 mr-5 md:px-3 hover:opacity-50 transition-all duration-500 ease-in-out last:pr-0 last:mr-0">
    <Link href={`/${href}`} passHref>
      <a className="inline-block w-full no-underline hover:text-gray-800">
        {label}
      </a>
    </Link>
  </li>
)

export default Footer_Item;
