const Header_Hamburger = ({onClick, fillColor}) => (
  <div className="block md:hidden" onClick={onClick}>
    <button className="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white focus:border-white">
      <svg
        className="w-5 h-5"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Menu</title>
        <path
          fill={fillColor}
          d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
        />
      </svg>
    </button>
  </div>
);

export default Header_Hamburger;
