import React from "react";
import cn from "classnames"

import Spinner from "./Spinner"
import IconAddFile from "./IconAddFile"
import IconRemoveFile from "./IconRemoveFile"

const SectionContact_Form = () => {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [subject, setSubject] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [isSendingMessage, setIsSendingMessage] = React.useState(false)
  const [isValidContactForm, setIsValidContactForm] = React.useState(false)
  const [isContactAttempt, setIsContactAttempt] = React.useState(false)
  const [messageNotification, setMessageNotification] = React.useState("")
  const [files, setFiles] = React.useState([])

  React.useEffect(() => {
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmailValid = emailRegexp.test(String(email).toLowerCase())

    isEmailValid || phone
      ? setIsValidContactForm(true)
      : setIsValidContactForm(false)
  }, [email, phone])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (name === "sentry") {
      // this is sentry error handling testing
      // eslint-disable-next-line no-undef
      myUndefinedFunction()
    }

    setIsContactAttempt(true)
    setTimeout(() => setIsContactAttempt(false), 3000)

    if (!isValidContactForm) return

    const formData = new FormData()
    formData.append(
      "data",
      JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      })
    )
    files.forEach((file) =>
      formData.append("files.attachment", file, file.name)
    )

    setIsSendingMessage(true)
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/contacts?populate[0]=attachment`, {
      method: "POST",
      body: formData,
    }).then((r) => {
      setIsSendingMessage(false)
      r.status === 200 ?
        setMessageNotification(
          "Vielen Dank für Ihre Nachricht! Ich werde Sie zeitnah kontaktieren."
        ) : setMessageNotification(
          "Da hat etwas nicht geklappt! Bitte versuchen Sie es erneut."
        )
    })
  }

  const clearMessageBlock = () => {
    // TODO: mikhail. refactor to use reducer
    setName("")
    setEmail("")
    setPhone("")
    setSubject("")
    setMessage("")
    setMessageNotification("")
    setFiles([])
  }

  const addFile = (e) => {
    setFiles([...files, e.target.files[0]])
  }

  const removeFile = (filename) => {
    if (!filename) return
    const newFiles = files.filter((file) => file.name !== filename)
    setFiles(newFiles)
  }

  const inputSharedStyle =
    "px-4 py-3 text-gray-700 bg-[#f7fafc] border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 transition-all duration-500 ease-in-out w-full"

  return isSendingMessage ? (
    <Spinner />
  ) : messageNotification !== "" ? (
    <div className="pb-10 mx-10 sm:mx-40" onClick={clearMessageBlock}>
      {messageNotification}
    </div>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col pb-10 mx-10 text-base sm:text-lg md:text-base lg:text-xl md:mx-20 lg:mx-40 xl:mx-64"
    >
      <div className="flex flex-col w-full md:flex-row">
        <label className="flex-grow md:mr-5 last:mr-0" htmlFor="name">
          {/* labels are present only for Lighthouse SEO reasons, so hiding them */}
          <div className="my-1">Name</div>
          <input
            id="name"
            className={`${inputSharedStyle}`}
            type="text"
            value={name}
            onChange={({target: {value}}) => setName(value)}
          />
        </label>
        <label className="flex-grow md:mr-5 last:mr-0" htmlFor="email">
          <div className="my-1">Emailadresse</div>
          <input
            id="email"
            className={cn(
              {
                "border-gray-200": !isContactAttempt || isValidContactForm,
                "border-yellow-800": isContactAttempt && !isValidContactForm,
              },
              inputSharedStyle
            )}
            type="text"
            value={email}
            onChange={({target: {value}}) => setEmail(value.trim())}
          />
        </label>
        <label className="flex-grow md:mr-5 last:mr-0" htmlFor="phone">
          <div className="my-1">Handynummer</div>
          <input
            id="phone"
            className={cn(
              {
                "border-gray-200": !isContactAttempt || isValidContactForm,
                "border-yellow-800": isContactAttempt && !isValidContactForm,
              },
              inputSharedStyle
            )}
            type="text"
            value={phone}
            onChange={({target: {value}}) => setPhone(value.trim())}
          />
        </label>
      </div>
      <p
        className={cn(
          {
            "invisible opacity-0": !isContactAttempt || isValidContactForm,
            "visible opacity-100": isContactAttempt && !isValidContactForm,
          },
          "italic text-yellow-800 transition-all duration-500 ease-in-out"
        )}
      >
        Bitte geben Sie Telefonnummer und Emailadresse an, damit ich Sie
        kontaktieren kann
      </p>
      <div className="mb-4">
        <label className="flex-grow md:mr-5 last:mr-0" htmlFor="subject">
          <div className="my-1">Betreff</div>
          <input
            id="subject"
            className={`${inputSharedStyle} w-full`}
            type="text"
            value={subject}
            onChange={({target: {value}}) => setSubject(value)}
          />
        </label>
      </div>
      <div>
        <label className="flex-grow md:mr-5 last:mr-0" htmlFor="message">
          <div className="my-1">Nachricht</div>
          <textarea
            id="message"
            className={`${inputSharedStyle} w-full`}
            value={message}
            onChange={({target: {value}}) => setMessage(value)}
            rows="6"
          />
        </label>
      </div>
      {files.map((file) => (
        <div key={file.name}>
          <div className="inline-block mx-2">{file.name}</div>
          <div onClick={() => removeFile(file.name)}>
            <IconRemoveFile />
          </div>
        </div>
      ))}
      {files.length === 0 && (
        <div className="my-2">
          <label className="cursor-pointer">
            <IconAddFile />
            <div className="mx-2">Datei hinzufügen (10mb max)</div>
            <input onChange={addFile} type="file" className="hidden" />
          </label>
        </div>
      )}
      <button
        className="px-4 py-2 mt-6 bg-white border border-gray-400 rounded shadow focus:outline-none hover:bg-gray-100 my-tap-highlight"
        type="submit"
        aria-label="Senden nachricht"
      >
        Senden
      </button>
      <div className="self-center mt-2 mb-6 text-sm text-yellow-800 sm:text-center sm:w-2/3">
        Mit dem Absenden dieses Kontaktformulars erklären Sie sich mit unseren
        Datenschutzbestimmungen einverstanden.
      </div>
    </form>
  )
}

export default SectionContact_Form
