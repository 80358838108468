import Link from "next/link";
import {motion} from "framer-motion";
import Header_Hamburger from "./Header_Hamburger";
import Header_Navbar from "./Header_Navbar";
import {LogoLink} from "./Logo";
import {useState, useEffect, useRef} from "react";
import cn from "classnames";
import {COLORS} from "../lib/utils";

const variants = {
  opened: {opacity: 1, y: 0},
  closed: {opacity: 1, y: "-100%"},
};

const Header = ({navbarItems}) => {
  const node = useRef();

  const [dimensions, setDimensions] = useState({
    height: typeof window === "undefined" ? 0 : window.innerHeight,
    width: typeof window === "undefined" ? 0 : window.innerWidth,
  });

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  useEffect(() => {
    if (isMenuOpened) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpened]);

  useEffect(() => {
    const handleResize = () =>
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });

    window.addEventListener("resize", handleResize);

    return (_) => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsMenuOpened(false);
  };

  const onNavbarItemClick = () => setIsMenuOpened(false);

  return (
    <nav
      id="header"
      className={cn(
        "container fixed top-0 z-10 flex flex-row w-full px-5 font-medium text-teal-900 bg-white md:my-header-height",
        {
          "shadow-md": isMenuOpened,
        }
      )}
    >
      {!isMenuOpened && (
        <div className="self-center w-16 cursor-pointer my-tap-highlight">
          <Link href="/">
            {/* for Lighthouse SEO reasons using it this way instead of wrapping <Logo /> in <a> */}
            <LogoLink color={COLORS.TEAL_800} />
          </Link>
        </div>
      )}
      <div
        className={cn("flex flex-col justify-end w-full py-4 md:flex-row", {
          "items-end": !isMenuOpened,
        })}
      >
        {!isMenuOpened && (
          <div className="flex md:inline-block">
            <Header_Hamburger
              onClick={() => setIsMenuOpened(true)}
              // TODO: mikhail. extract to some COLORS = { teal900: "#234e52" } in js
              fillColor="#234e52"
            />
          </div>
        )}
        <motion.nav
          animate={
            dimensions.width < 768
              ? isMenuOpened
                ? "opened"
                : "closed"
              : "opened"
          }
          variants={variants}
        >
          <div ref={node}>
            <Header_Navbar
              navbarItems={navbarItems}
              isMenuOpened={isMenuOpened}
              onClick={onNavbarItemClick}
            />
          </div>
        </motion.nav>
      </div>
    </nav>
  );
};

export default Header;
