import SectionServices_Work from "./SectionServices_Work";
import Section_Title from "./Section_Title";
import Section_Content from "./Section_Content";
import Section_Description from "./Section_Description";

const SectionServices = ({data}) => {
  return (
    <section id={data.uid} className="flex flex-col w-full">
      <Section_Title title={data.title} />
      <Section_Content>
        <Section_Description description={data.description} />
        <div className="flex flex-col sm:justify-center sm:flex-wrap sm:flex-row md:justify-evenly">
          {data.works.data.map((work, i) => (
            <SectionServices_Work
              key={work.id}
              work={work}
              isEven={i % 2 === 0}
            />
          ))}
        </div>
      </Section_Content>
    </section>
  );
};

export default SectionServices;
