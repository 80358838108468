import Section_Title from "./Section_Title"
import Section_Content from "./Section_Content"
import SectionContact_Form from "./SectionContact_Form"
import Section_Description from "./Section_Description"

const SectionContact = ({data}) => {
  return (
    <section id={data.uid} className="flex flex-col w-full mb-10">
      <Section_Title title={data.title} />
      <Section_Content>
        <Section_Description description={data.description} />
        <SectionContact_Form />
      </Section_Content>
    </section>
  )
}

export default SectionContact
